import { APIDCN, apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { UtilityActionType } from './types';

import type { UtilityAction, UtilityModel } from './types';

const UtilityDefault: UtilityModel = {
  airport: []
};

const UtilityReducer = (state: UtilityModel = UtilityDefault, action: UtilityAction): UtilityModel => {
  switch (action.type) {
    case UtilityActionType.GetAirportList:
      return { ...state, airport: action.data };

    default:
      return { ...state };
  }
};

const UtilityCommand = {
  postAirportList: (search: string): TAction<UtilityAction, void> => {
    return (dispatch: TDispatch<UtilityAction>) => {
      return APIDCN.post('/inventory/1.0/admin/airline/search-iata', {
        iata: `${search}%`
      })
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: UtilityActionType.GetAirportList
          });
        });
    };
  },
  PostSendOtp: (payload: { destination: string, destination_name: string }, token: string): Promise<string> => {
    return apiFetch(token).post('/notification/1.0/otp/send', payload)
      .then((response) => {
        if (response.status === 200) {
          notify.success(`Periksa email anda untuk kode OTP`);

          return 'ok';
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  },
  PostVerifyOtp: (payload: { code: string, destination: string }, token: string): Promise<string> => {
    return apiFetch(token).post('/notification/1.0/otp/verify', payload)
      .then((response) => {
        if (response.status === 200) {
          return 'ok';
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  }
};

export { UtilityCommand, UtilityReducer, UtilityDefault };
